<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" element-loading-text="加载中...">
				<div class="tableConTable">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<span class="tct_tit">{{$t('i18nn_2f47c3d499a6e740')}}</span>
									<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
									  <el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
									</el-tooltip> -->
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<!-- <el-button  @click="updatePartnerDataAction($event,$t('i18nn_4e9fc68608c60999'))" type="danger" size="small" icon="el-icon-close">undefined</el-button> -->
								<!-- <span v-if="!isSel"> -->
								<!-- <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null, $t('i18nn_24c8f46012a25c89'))">undefined</el-button> -->

								<!-- <el-button @click="completeAction($event,null)" type="danger" size="small" icon="el-icon-finished">undefined</el-button> -->
								<!-- </span> -->
								<!--右边容器-->
								<!-- <slot name="topRight"></slot> -->
								<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
							</el-col>
						</el-row>
					</div>

					<div>
						<div class="filterCon" style="padding-top: 10px;">
							<el-row type="flex" justify="end">
								<el-col :span="24">
									<ul class="filterConList">
										<!-- <li>
                      <span>undefined</span>

                      <el-date-picker
                        v-model="filterData.statisticsCurrentDate"
                        value-format="yyyy-MM-dd"
                        type="date"
                        size="small"
                        placeholder="undefined"
                        @change="initData()"
                      ></el-date-picker>
                    </li> -->
										<!-- <li>
											<span>{{ $t('i18nn_29991afa9781d554') }}</span>
											<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
										</li> -->
										<li>
											<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
											<SelAgentUser ref="SelAgentUser" @change="changeAgentUser"></SelAgentUser>
										</li>
										<li>
											<span>{{$t('i18nn_d42355e6aef72227')}}</span>
											<el-date-picker
												v-model="filterData.daterange"
												size="small"
												type="daterange"
												align="right"
												:clearable="true"
												unlink-panels
												range-separator="-"
												start-:placeholder="$t('i18nn_7d2ffc27a09ff792')"
												end-:placeholder="$t('i18nn_46f7be1133b3e2c6')"
												:picker-options="pickerOptions"
												format="yyyy-MM-dd"
												value-format="yyyy-MM-dd"
												@change="initData()"
											></el-date-picker>
										</li>
										<!-- <li class="">
											<span>SKU</span>
											<el-input
												:placeholder="$t('FormMsg.Please_Enter')"
												size="small"
												v-model="filterData.goodsSku"
												maxlength="50"
												:clearable="true"
												style="width: 250px;"
											></el-input>
										</li> -->

										<li class="">
											<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
											<whNoSelect @changeData="changWhNo"></whNoSelect>
										</li>

										<li>
											<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{ $t('i18nn_1e7246dd6ccc5539') }}</el-button>
										</li>
									</ul>
									<!-- <el-select filterable clearable size="small" v-model="queryParamObj.feeType" :placeholder="$t('i18nn_5a9aefbc03c778f7')" style="width: 150px;">
                    <el-option v-for="item in selectOption.matching_fee" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
                  </el-select> -->
								</el-col>
							</el-row>
						</div>
					</div>

					<div>
						<el-card style="margin-bottom: 10px;">
							<!-- <div slot="header"><span>{{$t('i18nn_684a2afb069b6016')}}</span></div> -->
							<div>
								<OrderByCharts  :openTime="ChartsMonDataOpenTime" :parm="ChartsMonDataParm" :chartData="ChartDataMon" @clickMon="clickMon"></OrderByCharts>
							</div>
						</el-card>
						<el-card style="margin-bottom: 10px;">
							<!-- <div slot="header"><span>{{$t('i18nn_684a2afb069b6016')}}</span></div> -->
							<div>
								<OrderByCharts  :openTime="ChartsDayDataOpenTime" :parm="ChartsDayDataParm" :chartData="ChartDataDay"></OrderByCharts>
							</div>
						</el-card>
					</div>
				</div>
				<!-- <div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div> -->
			</div>
		</div>
		<!-- excel 导出排序 -->
		<!-- <whExcelCustom
			:openTime="excelOpenTime"
			:excelHead="excelHead"
			:excelData="expExcelData"
			:pagination="pagination"
			:excelName="excelName"
			:excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhReportSkuGroupStockPageList"
			:expHttpFilter="pageFilterData()"
		></whExcelCustom> -->
		<!--sku趋势统计-->
		<!-- <DialogInStSkuCharts :openTime="ChartsDataOpenTime" :parm="ChartsDataParm"></DialogInStSkuCharts> -->
		<!-- <WordChartsTest></WordChartsTest> -->
	</div>
</template>
<script>
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
import SelAgentUser from '@/components/Common/SelAgentUser.vue';
import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
// import whExcelCustdom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';

// import DialogInStSkuCharts from '@/components/WarehouseCenter2/statement/DialogInStSkuCharts.vue';

import OrderByCharts  from '@/components/StorageCenter/statement/OrderByCharts.vue';
// import WordChartsTest  from '@/components/StorageCenter/statement/WordChartsTest.vue';
export default {
	// name: 'HomeConfFrame',
	//meta信息seo用
	metaInfo: {
		title: 'HYTX-WMS-ADMIN'
	},
	components: {
		// WhPlace,
		// cusSelFuzzy,
		SelAgentUser,
		whNoSelect,
		// whExcelCustom,
		// DialogInStSkuCharts,
		OrderByCharts,
		// WordChartsTest
	},
	data() {
		return {
			//excel导出
			excelOpenTime: '',
			excelHead: [],
			expExcelData: [],
			excelName: '',
			excelOption: {},
			// popTest : false,
			pickerOptions: this.$PickerDate.pickerOptions_1(),
			ChartsMonDataOpenTime: '',
			ChartsMonDataParm: {},
			ChartDataMon:{},
			
			ChartsDayDataOpenTime: '',
			ChartsDayDataParm: {},
			ChartDataDay:{},
			
			// ChartsData2OpenTime: '',
			// ChartsData2Parm: {},
			// ChartData2:{},
			//表格数据
			//loading,表格数据
			loading: false,
			loading_load: false,
			//表格数据
			// tableData: {},
			//分页数据
			// pagination: {
			// 	current_page: 1,
			// 	page_sizes: [5, 10, 20, 30],
			// 	page_size: 20,
			// 	total: 0
			// },

			selectOption: {
				wh_no: []
				// wh_goods_fee_type: [],
				// wh_vol_unit:[],
				// statusList: [
				// 	{
				// 		value: '',
				// 		label: this.$t('i18nn_16853bda54120bf1')
				// 	},
				// 	{
				// 		value: '10',
				// 		label:this.$t('i18nn_7e14d2cd3996dcd2')
				// 	},
				// 	{
				// 		value: '45',
				// 		label:this.$t('i18nn_885c3cdb72cb2e0b')
				// 	}
				// ]
			},
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc', //desc降序，asc升序

				agentUser: '',
				// "accountPeriod":"",
				daterange: [],
				goodsSku: '',
				whNo: '',
				// userId:''
				// goodsSku: '',
				// goodsName:""
			}
		};
	},
	activated() {
		// this.initData();
	},
	//创建时
	created() {
		if(this.$route.query && this.$route.query.agentUser){
			this.filterData.agentUser = this.$route.query.agentUser;
			this.$nextTick(() => {
				this.$refs.SelAgentUser.initData(this.filterData.agentUser);
			});
		}
		this.initData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();
		// this.getWhNoData();
	},
	methods: {
		initData() {
			// this.pagination.current_page = 1;
			this.currentSel = {};
			this.multipleSelection = [];
			this.getPageData();
			this.getPageData2();
			this.$nextTick(() => {
				// this.$refs.multipleTable.doLayout();
			});
			// this.getDicData();
		},
		changeAgentUser(val) {
			this.filterData.agentUser = val;
			this.initData();
		},
		// changCus(data) {
		// 	console.log('changCus', data);
		// 	this.filterData.userId = data.userId;
		// 	this.initData();
		// },
		changWhNo(data) {
			console.log('changWhNo', data);
			this.filterData.whNo = data.code;
			// this.filterData.userId = data.userId;
			this.initData();
		},
		//导出 excel
		// exportExcel() {
		// 	let columns = [
		// 		{
		// 			title: 'SKU',
		// 			key: 'goodsSku'
		// 		},
		// 		{
		// 			title: this.$t('i18nn_cabc5dc617eef30e'),
		// 			key: 'reportDate'
		// 		},
		// 		{
		// 			title: this.$t('i18nn_de53ab10322cd38a'),
		// 			key: 'stock'
		// 		},
		// 		{
		// 			title: '总体积(m³)',
		// 			key: 'totalVol'
		// 		},
		// 		{
		// 			title: '总重量(kg)',
		// 			key: 'totalWeight'
		// 		},
		// 		{
		//  		title: this.$t('i18nn_5acbec83efb27445'),
		// 			key: 'whNo'
		// 		},
		// 		{
		// 			title: this.$t('i18nn_0e240c5802d17fea'),
		// 			key: 'vol'
		// 		},
		// 		{
		// 			title: this.$t('i18nn_11e41b2ec3d872ed'),
		// 			key: 'weight'
		// 		}
		// 	];
		// 	let Data = this.tableData;

		// 	this.expExcelData = Data;
		// 	this.excelHead = columns;
		// 	this.excelName = 'InventoryStatisticsSkuGroup';
		// 	this.excelOption = { height: 20 };
		// 	this.excelOpenTime = new Date().getTime();
		// },

		//分页的筛选项数据
		pageFilterData() {
			console.log('daterange', this.filterData.daterange);
			let startTime = '';
			let endTime = '';
			if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				startTime = this.filterData.daterange[0];
				endTime = this.filterData.daterange[1];
			} else {
				// this.$message.warning(this.$t('tips.Please_Sel_date'));
			}
// startD endD
			return {
				// userId: this.filterData.userId ? this.filterData.userId : null,
				proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
				// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
				startD: startTime ? startTime : null,
				endD: endTime ? endTime : null,
				// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
				whNo: this.filterData.whNo ? this.filterData.whNo : null
			};
		},

		//请求分页数据
		getPageData() {
			// let _this = this;

			// let filterData = Object.assign({
			// 	offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
			// 	limit: this.pagination.page_size, //当前页显示数目
			// },this.pageFilterData())

			let filterData = this.pageFilterData();
			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.WhOrderStatisticsByMon, filterData)
				.then(({ data }) => {
					
					this.loading_load = false;
					if (200 == data.code && data.data) {
						//表格显示数据
						// this.tableData = data.data;
						let charData = data.data;
						charData.SDS = charData.SDS?charData.SDS:[];
						charData.STF = charData.STF?charData.STF:[];
						
						this.initChar(charData.SDS,charData.STF);
						
					} else {
						this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
					}
				})
				.catch(error => {
					console.log(error);
					this.$message.error(this.$t('tips.queryRequestFailed'));
					this.loading_load = false;
				});
		},
		
		//请求分页数据
		getPageData2(dateMon) {
			// let _this = this;
		
			// let filterData = Object.assign({
			// 	offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
			// 	limit: this.pagination.page_size, //当前页显示数目
			// },this.pageFilterData())
		
			let filterData = this.pageFilterData();
			//显示选择数据最后月的当月数据(1号至当月最后一号)
			let titleMon = "";
			//点击的值
			if(dateMon){
				filterData.endD = dateMon;
			}  
			//筛选传入的，最后月的值
			if(filterData.endD){
				try{
					let newDataSec = this.$Utils.getMonByDaySection(filterData.endD);
					console.log("newDataSec",newDataSec);
					filterData.startD = newDataSec[0] ? newDataSec[0] : null;
					filterData.endD = newDataSec[1] ? newDataSec[1] : null;
					titleMon = "("+newDataSec[2]+")";
				}catch(e){
					console.log(e)
					//TODO handle the exception
				}
			}
			
			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.WhOrderStatisticsByDay, filterData)
				.then(({ data }) => {
					
					this.loading_load = false;
					if (200 == data.code && data.data) {
						//表格显示数据
						// this.tableData = data.data;
						let charData = data.data;
						charData.SDS = charData.SDS?charData.SDS:[];
						charData.STF = charData.STF?charData.STF:[];
						
						this.initChar2(charData.SDS,charData.STF,titleMon);
						
					} else {
						this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
					}
				})
				.catch(error => {
					console.log(error);
					this.$message.error(this.$t('tips.queryRequestFailed'));
					this.loading_load = false;
				});
		},
		//选择行
		handleCurrentChange(row, event, column) {
			// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
		},
		//多选
		handleSelectionChange(val) {
			console.log(val);
			// this.multipleSelection = val;
		},

		//打开SKU趋势统计详情
		// openSkuCharts(event, row) {
		// 	event.stopPropagation();
		// 	this.ChartsDataParm = {
		// 		userId: row.userId, //"用户ID",
		// 		goodsSku: row.goodsSku, //this.$t('i18nn_74ed28f373ea14da'),
		// 		whNo: row.whNo //this.$t('i18nn_5acbec83efb27445')
		// 	};
		// 	this.ChartsDataOpenTime = new Date().getTime();
		// },
		
		initChar(data,data2){
			this.ChartsMonDataParm = { };
			this.ChartsMonDataOpenTime = new Date().getTime();
			let XData = [],YData1=[],YData2=[];
			data.forEach(item=>{
				XData.push(item.commitDate);
				YData1.push(item.totalCount);
			});
			data2.forEach(item=>{
				YData2.push(item.totalCount);
			});
			this.ChartDataMon = {
				title: this.$t('Storage.chart.OrderStatisticsByMon_1'),
				type:'1',
				XData: XData,
				YData1:YData1,
				YData2:YData2
			}
		},
		initChar2(data,data2,title){
			this.ChartsDayDataParm = { };
			this.ChartsDayDataOpenTime = new Date().getTime();
			let XData = [],YData1=[],YData2=[];
			data.forEach(item=>{
				XData.push(item.commitDate);
				YData1.push(item.dayCount);
			});
			data2.forEach(item=>{
				YData2.push(item.dayCount);
			});
			this.ChartDataDay = {
				title: this.$t('Storage.chart.OrderStatisticsByMon_2')+title,
				XData: XData,
				YData1:YData1,
				YData2:YData2
			}
		},
		
		//点击按月统计
		clickMon(val){
			this.getPageData2(val);
		},
		
		
		// initCharSTF(data){
		// 	this.ChartsData2Parm = { };
		// 	this.ChartsData2OpenTime = new Date().getTime();
		// 	let XData = [],YData=[];
		// 	data.forEach(item=>{
		// 		XData.push(item.commitDate);
		// 		YData.push(item.totalCount);
		// 	});
		// 	this.ChartData2 = {
		// 		title: "自提单-订单按月统计",
		// 		XData: XData,
		// 		YData: YData
		// 	}
		// },
		
		//提交数据
		// postData(url, formData, callback) {
		// 	// let _this = this;
		// 	this.loading = true;

		// 	this.$http
		// 		.put(url, formData)
		// 		.then(({ data }) => {
		// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
		// 			console.log(data);
		// 			
		// 			this.loading = false;
		// 			if (200 == data.code) {
		// 				callback();
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('dbe331ab679cd67f');
		// 				}
		// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
		// 					type: 'warning'
		// 				});
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log(this.$t('tips.submitError'));
		// 			this.loading = false;
		// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
		// 				type: 'warning'
		// 			});
		// 		});
		// },
		//查询仓库
		// getWhNoData() {
		// 	this.$http
		// 		.get(this.$urlConfig.whNoPageList)
		// 		.then(({ data }) => {
		// 			console.log('查询，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.rows) {
		// 				this.selectOption.wh_no = data.rows;
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = '查询仓库失败,请重试';
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log(this.$t('tips.errorData'));
		// 			this.$message.error('查询仓库失败,请重试！');
		// 		});
		// },
		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);

		// 	// this.loading_load = true;
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_goods_fee_type'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 				// this.selectOption.wh_vol_unit = data.data["wh_vol_unit"];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = '查询数据字典失败,请重试';
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error('查询数据字典接口失败,请重试！');
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// @import url(@/assets/css/service_mod.less);
</style>
